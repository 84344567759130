import type { LegacyRDMDOpts as Opts, LegacyRDMDProps as Props } from '@readme/iso';

import * as md from '@readme/markdown';
import React, { useMemo } from 'react';

import components from '@core/utils/rdmdComponentOverrides';

import './style.scss';

const truthy = (value: string | null) => {
  if (!value) return false;
  if (['on', 'true'].includes(value)) return true;
  if (['off', 'false'].includes(value)) return false;
  return !!value;
};

function useRDMD(bodyParam: string, toc = true, excerpt = false, optsParam: Opts) {
  const memoizedOpts = useMemo(() => {
    const params = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : {});

    return {
      ...optsParam,
      ...(params.has('rdmdCompatibilityMode') && { compatibilityMode: truthy(params.get('rdmdCompatibilityMode')) }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(optsParam)]);

  // normalize RDMD text and options
  const [body, opts] = useMemo(() => md.setup(bodyParam || '', memoizedOpts), [bodyParam, memoizedOpts]);
  const ast = useMemo(() => md.reactProcessor(opts, components).parse(body), [body, opts]);
  const content = useMemo(() => md.react(excerpt ? ast.children[0] : ast, opts, components), [ast, excerpt, opts]);
  const tocContent = useMemo(() => md.reactTOC(ast, opts), [ast, opts]);

  return toc ? tocContent : content;
}

export const TOC = ({ body, opts = {} }) => {
  return useRDMD(body, true, false, opts);
};

const RDMD = ({ body, children, skipBaseClassName, className, excerpt, opts = {}, Tag = 'div', ...props }: Props) => {
  const content = useRDMD((body || children || '') as string, false, excerpt, opts);
  const classes = [skipBaseClassName !== true && 'markdown-body', className || ''];

  return (
    <Tag {...props} className={`rm-Markdown ${classes.filter(c => c).join(' ')}`} data-testid="RDMD">
      {content}
    </Tag>
  );
};

export default RDMD;
